.form {
  &.-help {
    opacity: 0.5;
    display: block;
  }

  &.-group-wrapper {
    display: flex;
    margin: 12px 0;
    margin-bottom: spacer(5);
    margin-top: spacer(3);
  }

  &.-group-wrapper-no-flex {
    display: block !important;
  }

  &.-group-element-wrapper {
    min-width: 100px;
  }

  &.-group-element {
    cursor: pointer;
    margin-right: spacer(5);
    position: relative;
    user-select: none;

    input {
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;
      position: absolute;

      &:checked ~ .-group-element-checkbox {
        border: 2px solid $colorPrimaryGreen;
        &.-checkbox-blue {
          border-color: var(--checkbox-blue-border-color);
          &.-defaulted {
            border-color: var(--checkbox-blue-defaulted-border-color);
          }
          &.-subCheckbox {
            border-color: var(--checkbox-blue-subCheckbox-border-color);
          }
        }
      }

      &:checked ~ .-group-element-checkbox:after {
        display: block;
      }

      &:checked ~ .-group-element-radio {
        border: 2px solid $colorPrimaryGreen;
      }

      &:checked ~ .-group-element-radio:after {
        display: block;
      }
    }

    &:hover input ~ .-group-element-checkbox {
      border: 2px solid $colorPrimaryGreen;
      &.-checkbox-blue {
        border-color: var(--checkbox-blue-border-color);
        &.-defaulted,
        &.-subCheckbox {
          border-color: var(--checkbox-blue-defaulted-border-color);
        }
      }
    }

    &:hover input ~ .-group-element-radio {
      border: 2px solid $colorPrimaryGreen;
    }
  }
  &.-group-element-label {
    margin-left: 33px;
  }

  &.-group-element-checkbox {
    @extend .group-element-input;
    border-radius: 4px;

    &:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: 2px solid $colorPrimaryGreen;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.-checkbox-blue:after {
      border-color: var(--checkbox-blue-border-color);

      &.-defaulted:after {
        border-color: var(--checkbox-blue-defaulted-border-color);
      }

      &.-subCheckbox:after {
        border-color: $colorAccentLightTeal;
      }
    }

    &.-checkbox-blue {
      border: 2px solid var(--checkbox-blue-defaulted-border-color);
      &.-defaulted:after {
        border-color: var(--checkbox-blue-defaulted-border-color);
      }

      &.-subCheckbox {
        border-color: $colorAccentLightTeal;
      }
    }
  }

  &.-group-element-radio {
    @extend .group-element-input;
    border-radius: 50%;

    &:after {
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $colorPrimaryGreen;
    }
  }
}

.noSelector {
  &:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
}

.group-element-input {
  height: spacer(5);
  width: spacer(5);
  background-color: var(--input-radio-bg-color) !important;
  border: 2px solid var(--input-radio-border-color);
  position: absolute;
  left: 0;

  &.-checkbox-blue {
    &.-subCheckbox {
      border-color: var(--checkbox-blue-subCheckbox-border-color);
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}

@media (max-width: $screen-sm-max) {
  .form {
    &.-group {
      margin-bottom: spacer(5);
    }
    &.-group-wrapper {
      display: block;
      margin-bottom: spacer(2);
    }

    &.-group-element-wrapper {
      margin: spacer(5) 0;

      &:first-child {
        margin-top: spacer(3);
      }

      &:last-child {
        margin-bottom: spacer(2);
      }
    }
  }
}
