:host(.hideButton) {
  display: none !important;
}

wpbutton {
  // fontLarge
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4375rem;

  background-color: var(--button-primary-background-color);
  border: $borderWidth solid var(--button-primary-border-color);
  border-radius: spacer(5);
  color: var(--button-primary-text-color);
  margin: 20px spacer(2) spacer(3) 0;
  user-select: none;
  display: inline-block !important;
  transition: 0.3s;

  .-infinite-spin {
    animation: rotate 2s linear infinite;
  }

  a {
    color: var(--button-primary-text-color) !important;
    cursor: pointer;
    icon {
      &.-refresh {
        margin: auto 0 auto 6px;
      }
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    background-color: var(--button-disabled-background-color);
    border: $borderWidth solid var(--button-disabled-background-color);
    color: var(--button-disabled-text-color);
  }

  &:not([disabled]):hover {
    background-color: var(--button-primary-background-hover-color);
    color: var(--button-primary-text-hover-color);
    border: $borderWidth solid var(--button-primary-background-hover-color);
    a {
      color: var(--button-primary-text-hover-color) !important;
      text-decoration: none;
      icon {
        svg > * {
          fill: var(--button-primary-text-hover-color);
        }
      }
    }
    icon {
      color: var(--button-primary-text-hover-color) !important;
    }
  }

  & a {
    padding: calc(#{spacer(3)} - #{$borderWidth}) calc(#{spacer(6)} - #{$borderWidth});
    display: flex;
    position: relative;

    &.-external {
      padding: calc(#{spacer(3)} - #{$borderWidth}) calc(#{spacer(6)} - #{$borderWidth});

      icon {
        display: flex;
        margin: auto 0 auto spacer(2);
        svg > * {
          fill: var(--button-primary-text-color);
        }
      }
    }
    &:focus {
      color: $colorSupportingWhite !important;
    }
  }

  // &.-gray {
  //   background-color: $colorTintPrimaryBlackT60;
  //   border-color: $colorTintPrimaryBlackT60;
  //   a {
  //     color: $colorSupportingWhite !important;
  //   }

  //   &:not([disabled]):hover {
  //     background-color: transparent;

  //     a {
  //       color: $colorTintPrimaryBlackT60 !important;
  //       .-refresh svg > * {
  //         fill: $colorSupportingWhite !important;
  //       }
  //     }
  //   }
  // }

  &.-secondary,
  &.-outline,
  &.-gray {
    color: var(--button-secondary-text-color) !important;
    background-color: transparent;
    border: $borderWidth solid var(-button-secondary-border-color);

    a {
      color: var(--button-secondary-text-color) !important;
      &.-external {
        icon {
          svg > * {
            fill: var(--button-secondary-text-color);
          }
        }
      }
    }

    &:hover icon svg > * {
      fill: var(--button-secondary-text-hover-color) !important;
    }

    &.-external::after {
      // background-image: url('#{$path-icons}/external-link-white.svg');
    }
    &:not([disabled]):hover {
      color: var(--button-secondary-text-hover-color);
      background-color: var(--button-secondary-background-hover-color);
      a {
        color: var(--button-secondary-text-hover-color) !important;
        .-refresh svg > * {
          fill:  var(--button-secondary-text-hover-color) !important;
        }
      }

      &.-external::after {
        // background-image: url('#{$path-icons}/external-link-grey.svg');
      }
    }
  }

  // &.-outline {
  //   background-color: transparent;
  //   border: $borderWidth solid $colorPrimaryGreen;
  //   color: $colorSupportingWhite !important;
  //   a {
  //     color: $colorSupportingWhite !important;
  //   }
  //   &:not([disabled]):hover {
  //     background-color: $colorPrimaryGreen;
  //     .-refresh svg > * {
  //       fill: $colorSupportingWhite !important;
  //     }
  //     &.-external::after {
  //       // background-image: url('#{$path-icons}/external-link-white.svg');
  //     }
  //   }

  //   &.-external::after {
  //     // background-image: url('#{$path-icons}/external-link-white.svg');
  //   }
  // }

  &.-small {
    height: spacer(5);
    @extend .-fontSmall;
    a {
      padding: 0 spacer(4);
    }
  }
}
