multi-select-dropdown {
  .dropdown {
    color: var(--dropdown-text-color);
    &.-parent {
      position: relative;
      display: inline-block;
      width: 450px;
      height: spacer(42);

      &.-lg {
        width: 312px;
      }
    }
    &.-header {
      display: flex;
      justify-content: space-between;
      background-color: $colorTintPrimaryBlackT80;
      border-radius: spacer(1);
      cursor: pointer;
      // border: 1px solid $colorTintPrimaryBlackT80;

      .-button {
        padding: spacer(3) spacer(4);
        border: none;
        user-select: none;
      }

      icon {
        float: right;
        margin: auto spacer(4);
        height: 13px;
        width: 13px;

        svg {
          margin-right: 0;
        }
      }
    }

    &-rotate-icon {
      transform: rotate(180deg);
    }

    &-active {
      border-radius: 0px !important;
      border-top-left-radius: spacer(1) !important;
      border-top-right-radius: spacer(1) !important;
    }

    &.-content {
      width: 100%;
      position: absolute;
      background-color: $colorShadePrimaryBlackS40;
      z-index: 3;
      border-bottom-left-radius: spacer(1);
      border-bottom-right-radius: spacer(1);
      overflow: hidden;
    }

    &.-content-option {
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      user-select: none;

      // border-left: 1px solid #6c858d;
      // border-right: 1px solid #6c858d;
      // &:last-child {
      //   border-bottom-left-radius: spacer(1);
      //   border-bottom-right-radius: spacer(1);
      //   border-bottom: 1px solid #6c858d;
      // }

      &:hover {
        background-color: $colorTintPrimaryBlackT80;
      }
    }
    &.-multiselect {
      position: relative;
      display: inline-block;
      max-width: 270px;
      height: spacerThree(45);
      .-checkbox-text {
        flex: 1;
      }
      .content-count {
        padding-left: 8px;
      }
      .content-padding {
        padding: 16px 0 32px;
      }
      .-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--input-background-color);
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        padding: spacerThree(5);
        cursor: pointer;
        border: 1px solid var(--input-border-color);
        .-button {
          border: none;
          user-select: none;
        }
        icon {
          float: right;
          margin-left: 10px;
          svg {
            margin-right: 0;
          }

          svg > * {
            fill: var(--dropdown-text-color);
          }
        }
        .-icon-container {
          display: flex;
          align-items: center;
        }
      }
      .-content {
        width: 100%;
        background-color: var(--dropdown-pull-down-bg-color);
        border-radius: 4px;
        overflow: hidden;
        position: absolute;
        z-index: 3;
      }
      .-group-element {
        cursor: pointer;
        display: flex;
        position: relative;
        user-select: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        padding: 12px 16px;
        align-items: center;

        input {
          left: 0;
          width: 0;
          height: 0;
          opacity: 0;
          cursor: pointer;
          position: absolute;

          &:checked ~ .-checkbox-container {
            .-group-element-checkbox {
              border: 2px solid var(--dropdown-checkbox-checked-border-color);
              &.-checkbox-blue {
                border-color: var(--dropdown-checkbox-checked-border-color);
                &.-defaulted {
                  border-color: var(--dropdown-checkbox-checked-border-color);
                }
                &.-subCheckbox {
                  border-color: var(--dropdown-checkbox-checked-border-color);
                }
              }

              &:after {
                display: block;
              }
            }
          }
        }

        .-checkbox-container {
          position: relative;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }

        &:hover {
          .-checkbox-container {
            background-color: var(--dropdown-checkbox-hover-bg-color);
          }
        }
      }

      .-group-element-checkbox {
        @extend .group-element-input;
        border-radius: 2px;
        height: 18px;
        width: 18px;
        border: 2px solid var(--dropdown-checkbox-default-color);
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        background-color: unset !important;
        &:after {
          left: 5px;
          top: 0px;
          width: 4px;
          height: 9px;
          border: 2px solid var(--dropdown-checkbox-default-color);
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &.-checkbox-blue:after {
          border-color: var(--dropdown-checkbox-checked-tick-color);
        }
      }

      .-content-option {
        text-decoration: none;
        display: block;
        cursor: pointer;
        user-select: none;
        height: 48px;
        min-width: 100px;
      }
    }
  }
}

@media (max-width: ($screen-laptop-sm - 0.5)) {
  .-multiselect {
    max-width: 95% !important;
  }
}
@media (max-width: ($screen-lg - 0.5)) {
  multi-select-dropdown {
    .dropdown {
      &.-multiselect {
        .-header {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          text-transform: uppercase;
          border: none;
          background-color: unset;
          padding: unset;
          .-button {
            padding: unset;
            border: none;
            user-select: none;
          }
          icon {
            display: none;
          }
        }
        .-content {
          background-color: unset;
          position: unset;
        }

        .-group-element {
          padding: unset;
          &:hover {
            .-checkbox-container {
              background-color: unset;
            }
          }
        }

        .-content-option {
          height: unset;
        }
      }
    }
  }
}

@media (max-width: ($screen-mobile-large - 0.5)) {
  multi-select-dropdown {
    .dropdown {
      &.-parent {
        width: 100%;
      }

      &.-multiselect {
        .-header-sort {
          div:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
