api-versions-convergence {
  margin: 12px 24px 12px 12px;
  .version-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: unset;
    padding: spacer(3) spacer(4);
    width: 160px;
    height: 44px;
    right: 14px;
    top: 14px;
    background: var(--version-control-bg-color);
    border: 1px solid var(--version-control-border-color);
    border-radius: 4px;
    span {
      font-weight: 600;
    }
  }
  .-icon-padding {
    padding-left: spacer(3);
  }
  .dropdown-wrapper {
    position: absolute;
    background: var(--version-control-bg-color);
    border-radius: 4px;
    border: 1px solid var(--version-control-border-color);
    width: 160px;
  }
  .dropdown {
    position: relative !important;
    display: inline-block;
    icon {
      svg > * {
        fill: var(--version-control-flip-color) !important;
      }
    }
    &-rotate-icon {
      transform: rotate(180deg);
      padding-right: spacer(3);
    }
  }
  .dropdown-content {
    display: block;
    position: relative;
    background-color: var(--version-control-bg-color);
    border: 4px;
    z-index: 2;
    :hover {
      color: var(--version-control-text-hover-color);
      background-color: var(--version-control-bg-hover-color);
    }
  }
  .dropdown-content a {
    color:var(--version-control-text-color);
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    display: block;
    text-align: center;
    width: 100%;
    border-top: 1px;
    text-align: left;
    padding: spacer(3) spacer(4);
  }
}
