answer-tile {
  .sf-answer-tile {
    &.-parent {
      background-color: var(--sf-answer-tile-bg-color);
      width: 218px;
      height: 287px;
      position: relative;
      padding: 30px spacer(5);
      padding-top: spacer(6);
      text-align: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transition: transform 200ms, box-shadow 200ms;
      border: $hairline solid var(--sf-answer-tile-border-color);
      // box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);

      &:hover {
        .sf-answer-tile {
          &.-radio {
            label {
              input ~ .-group-element-radio {
                border: 2px solid var(--input-radio-border-hover-color);
              }
              input ~ .-group-element-radio:after {
                display: block;
                background: var(--input-radio-selection-color);
              }
            }
          }
        }

        transform: scale(1.04);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
      }
    }

    &.-radio {
      margin-left: auto;
      margin-right: auto;

      label {
        input {
          &:checked ~ .-group-element-radio {
            border: 2px solid var(--input-radio-border-hover-color);
          }
          &:checked ~ .-group-element-radio:after {
            background: var(--input-radio-selection-color);
          }
        }
      }
    }

    &.-text {
      color: var(--sf-title-color);
      @extend .-fontMedium;
      padding-bottom: spacer(4);
      padding-top: spacer(5);
    }

    &.-secondaryText {
      color: var(--sf-text-color);
      @extend .-fontSmall;
      font-weight: 400;
    }
  }
}
