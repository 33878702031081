card-related {
  height: 100%;
  color: var(--card-product-text-color);
  border: 1px solid #f2f2f2;
  background-color: var(--card-product-bg-color);
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0px 8px 20px 0px #1b1b6f0a, 0px 4px 6px 0px #1b1b6f08, 0px 1px 0px 0px #1b1b6f0d;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .card-product {
    &.-title {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .-text-caption {
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.05em;
        color: var(--card-related-font-color);
      }
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin: unset;
      flex: 1;
      color: var(--card-product-title-color) !important;
    }
  }
  p {
    margin-bottom: spacer(2);
  }
  .grid {
    margin: 0 !important;
    padding: 0 !important;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          color: var(--card-product-link-color);
          &:hover {
            color: var(--card-product-link-hover-color);
            text-decoration: var(--card-product-link-hover-decoration);
          }
        }
      }
    }

    link-icon {
      flex-direction: column;
      a {
        color: var(--card-product-link-color);
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;

        icon,
        .link-icon.-parent.-text {
          margin-top: 0;
        }

        &:hover {
          color: var(--card-product-link-hover-color);
        }
      }
    }
  }
  wpbutton {
    margin-top: spacer(42);
  }

  @media (max-width: 575px) {
    .card-product {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
}
