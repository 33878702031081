api-pagination {
  .-pager {
    display: flex;
    justify-content: center;
    align-items: center;
    .-navigation-button {
      > div {
        display: flex;
        align-items: center;
      }
      width: spacer(60);
      margin: 0 spacerThree(5);
      link-icon {
        width: spacer(30);
      }
      link-icon.left-trans {
        &:hover {
          a > icon {
            margin-left: -6px;
          }
        }
        icon {
          transition: margin-left 250ms;
          text-align: initial;
          margin: auto 0;
          transform: rotate(0deg) scaleX(-1);
        }
      }
      .-prev-text {
        color: var(--link-color);
        cursor: pointer;
      }
    }
    .-prev-text:hover {
      &:hover {
        color: var(--link-hover-color);
        svg > * {
          fill: var(--link-hover-color);
        }
      }
      link-icon.left-trans {
        a > icon {
          margin-left: -6px;
        }
      }
    }
    &.-button {
      @include button-reset;
      margin: 0;
      list-style-type: none;
      padding-left: 0;
      width: spacer(30);
      height: spacer(30);
      border-radius: spacer(5);
      border: 2px solid var(--paginator-border-color);
      margin: 0 4px 0 4px;
      font-size: 16px;
      line-height: spacer(5);
      font-weight: 700;
      color: var(--paginator-text-color);
      &:hover {
        color: var(--paginator-active-text-color);
        background-color: var(--paginator-active-hover-bg-color);
      }
      &.is-active {
        color: var(--paginator-active-text-color);
        background-color: var(--paginator-active-hover-bg-color);
      }
    }
    &.-excess-icon {
      opacity: 0;

      &.is-active {
        opacity: 1;
      }
    }
  }
  ul.-pager {
    padding-left: 0;
    li {
      margin: 0;
      list-style-type: none;
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }
}
