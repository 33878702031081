app-signup {
  div.form.valign,
  div.col.valign {
    vertical-align: top;
  }

  .valign h2:first-of-type {
    margin-top: 60px;
  }
  site-logo {
    svg {
      g > path {
        fill: var(--site-logo-color) !important;
      }
    }
  }
  .inline-block {
    display: inline-block;
  }

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  a,
  a:visited {
    color: var(--link-color);
  }
  a:hover {
    cursor: pointer;
    color: var(--link-hover-color);
    text-decoration: none;
  }

  .signup h4 {
    line-height: 20px;
    color: var(--login-heading-color);
  }

  // .grey {
  //   color: #979797;
  // }

  // & .valign{
  //   display: inline-block;
  //   float: none;
  //   vertical-align: middle;
  // }

  // & .form .-question label {
  //   margin-bottom: 5px;
  //   margin-top:10px;
  // }

  // .col-md-offset-1 {
  //   // float: left;
  //   // float: right;
  // }

  // .signup h4 {
  //     padding: 0em 15%;
  // }

  .wrap {
    // max-width: 13em;
    width: 29%;
    vertical-align: middle;
  }

  p:last-child {
    padding-bottom: 5%;
  }

  .nav {
    z-index: 100;
    position: absolute;
    padding: 2em;
  }

  masthead .masthead.-container {
    min-height: 50em;
    width: 100%;
  }

  .masthead-centered {
    align-items: flex-start;
  }

  masthead-centered .masthead-centered.-container {
    min-height: 127ch;
  }

  .main-icon {
    display: inline-block;
    border-radius: 50%;
    border: 2.5px solid #fff;
    padding: 1.25em;
    margin: 2em 2em;
  }

  .signup {
    // h2:first-of-type {
    //   margin-bottom: 0;
    // }

    h2:last-child {
      margin-top: 0;
    }

    &.-cols {
      display: flex;
      margin: auto;
    }

    &.-row {
      width: 100%;
    }

    &.-submit-button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: spacer(4);
      icon {
        top: 1px;
        svg {
          top: 0;
          left: 0;
          & > * {
            fill: var(--button-primary-text-color);
          }
        }
      }
    }

    & wpbutton a {
      padding: 0 0 0 0;
      display: block;
    }

    &.-submit-button {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.4375rem;
      background-color: var(--button-primary-background-color);
      border: $borderWidth solid var(--button-primary-border-color);
      border-radius: spacer(5);
      color: var(--button-primary-text-color);
      margin: 0 6px 22px 0;
      user-select: none;
      display: inline-block !important;
      transition: 0.3s;
      width: 66%;
      text-align: center;

      padding: calc(#{spacer(3)} - #{$borderWidth}) calc(#{spacer(6)} - #{$borderWidth});

      &:hover {
        background-color: var(--button-primary-background-hover-color);
        color: var(--button-primary-text-hover-color);
        a {
          color: var(--button-primary-text-hover-color);
        }
        icon {
          color: var(--button-primary-text-hover-color);
          svg > * {
            fill: var(--button-primary-text-hover-color);
          }
        }
      }
    }

    &.-filler {
      margin-bottom: 24%;
    }

    &.-masthead-confirmation {
      padding-top: 140px;
    }

    &.-confirmation {
      height: 0;
      overflow: hidden;
    }

    &.-failure {
      color: $colorAccentPink;
      text-align: center;
    }
    .notification.-description {
      font-size: 13px !important;
    }
    .notification.-title {
      font-weight: 700;
    }
    &.-question {
      margin-left: auto;
      margin-right: auto;
      width: 66%;
    }
    ::-webkit-input-placeholder {
      color: $colorAccentLightGray;
    }
    ::-moz-placeholder {
      color: $colorAccentLightGray;
    }
    ::-ms-placeholder {
      color: $colorAccentLightGray;
    }
    ::placeholder {
      color: $colorAccentLightGray;
    }
  }

  .-main-padding {
    padding: spacer(7) 18% spacer(9) 18%;
  }

  label {
    margin: 0;
  }
}

@media (max-width: $screen-sm-max) {
  app-signup {
    .signup {
      &.valign {
        display: block;
        // margin-top: 4em;
      }
      &.valign.form {
        display: block;
        margin-top: 6em;
        padding-bottom: spacer(5);
      }
      &.-masthead {
        padding-top: spacer(7);
        display: inline-block;
      }
      &.-cols {
        display: block;
      }
      & .signup .form {
        // padding: 0 3em;
        width: 66%;
        margin: auto;
      }
    }

    .wrap {
      text-align: center;
    }
  }
}

@media (max-width: $screen-tablet) {
  app-signup {
    .nav {
      padding: 1em;
      width: 50%;
    }
    .-logo {
      img {
        margin: unset;
      }
    }
  }
}
