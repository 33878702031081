.-breadcrumb {
  display: inline-block;
  border-radius: spacer(4);
  padding: spacerThree(1) spacerThree(5);
  background: var(--masthead-tag-bg-color);
  border: 1.5px solid var(--masthead-tag-border-color);
  font-family: Source Sans Pro;
  font-size: spacerThree(5);
  font-style: normal;
  font-weight: 600;
  line-height: spacerThree(7);
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: spacer(6);
  color: var(--masthead-tag-text-color);
}

.-gradient-image {
  position: absolute;
  top: 0;
  left: 0;
}
.-gradient-image-container {
  position: relative;
  padding-top: 630px;
  height: 0;
}
app-homepage {
  .-homepage-container {
    max-width: 1536px;
    margin: 0 auto;
    &.-platforms {
      width: 1440px;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      .-content {
        padding: unset !important;
        position: relative;
        bottom: 108px;
        z-index: 3;
        margin-top: unset;
      }

      .-platforms-new {
        width: 1072px;
        max-width: 1072px;
        .-key-section {
          .grid {
            padding: 0;
            gap: 24px;
            gridColumn {
              padding: 0;
            }
          }
        }
        &.-welcome-section {
          position: relative;
          bottom: 30px;
        }
        &.-business-section {
          margin-top: spacer(66);
          .grid {
            padding: 0;
            gap: 16px;
            gridColumn {
              padding: 0;
            }
          }
          &:last-of-type {
            margin-bottom: spacer(66);
          }
        }
        &.-business-section > h3 {
          margin: 0;
          margin-bottom: 24px;
          color: var(--heading-color-branded) !important;
        }
      }
    }

    &.-enterprise {
      width: 1440px;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      .-content {
        padding: unset;
        position: relative;
        bottom: 48px;
        z-index: 3;
        margin-top: unset;
      }

      .-enterprise-new {
        width: 1168px;
        max-width: 1168px;
        &.-welcome-section {
          position: relative;
          bottom: 31px;
          masthead-gradient-updated {
            img {
              top: 0px;
            }
          }
        }
        &.-business-section {
          margin-top: spacer(66);
          .grid {
            padding: 0;
            gap: 16px;
            gridColumn {
              padding: 0;
            }
          }
          &:last-of-type {
            margin-bottom: spacer(66);
          }
        }
        &.-business-section > h3 {
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }

    &.-financial-institution {
      width: 1440px;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      .-content {
        padding: unset;
        position: relative;
        bottom: 48px;
        z-index: 3;
        margin-top: unset;
      }

      .-financial-institution-new {
        width: 1168px;
        max-width: 1168px;
        &.-welcome-section {
          position: relative;
          bottom: 31px;
          masthead-gradient-updated {
            h1 {
              width: 716px;
            }
            img {
              top: 0px;
            }
          }
        }
        &.-business-section {
          margin-top: spacer(66);
          .grid {
            padding: 0;
            gap: 16px;
            gridColumn {
              padding: 0;
            }
          }
          &:last-of-type {
            margin-bottom: spacer(66);
          }
        }
        &.-business-section > h3 {
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }

    &.-independent-software-vendor {
      width: 1440px;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      .-content {
        padding: unset;
        position: relative;
        bottom: 146px;
        z-index: 3;
        margin-top: unset;
      }

      .-independent-software-vendor-new {
        width: 1168px;
        max-width: 1168px;
        &.-welcome-section {
          position: relative;
          bottom: 31px;
          masthead-gradient-updated {
            h1 {
              width: 716px;
            }
            img {
              top: -2px;
            }
          }
        }
        &.-business-section {
          margin-top: spacer(66);
          .grid {
            padding: 0;
            gap: 16px;
            gridColumn {
              padding: 0;
            }
          }
          &:last-of-type {
            margin-bottom: spacer(66);
          }
        }
        &.-business-section > h3 {
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }

    &.-independent-sales-organization {
      width: 1440px;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      .-content {
        padding: unset;
        position: relative;
        bottom: 48px;
        z-index: 3;
        margin-top: unset;
      }

      .-independent-sales-organization-new {
        width: 1168px;
        max-width: 1168px;
        &.-welcome-section {
          position: relative;
          bottom: 31px;
          masthead-gradient-updated {
            h1 {
              width: 716px;
            }
            img {
              top: -2px;
            }
          }
        }
        &.-business-section {
          margin-top: spacer(66);
          .grid {
            padding: 0;
            gap: 16px;
            gridColumn {
              padding: 0;
            }
          }
          &:last-of-type {
            margin-bottom: spacer(66);
          }
        }
        &.-business-section > h3 {
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }

    &.-smb {
      width: 1440px;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      .-content {
        padding: unset;
        position: relative;
        bottom: 148px;
        z-index: 3;
        margin-top: unset;
      }

      .-smb-new {
        width: 1168px;
        max-width: 1168px;
        &.-welcome-section {
          position: relative;
          bottom: 35px;
          masthead-gradient-updated {
            img {
              top: 3px;
            }
          }
        }
        &.-business-section {
          margin-top: spacer(66);
          .grid {
            padding: 0;
            gap: 16px;
            gridColumn {
              padding: 0;
            }
          }
          &:last-of-type {
            margin-bottom: spacer(66);
          }
        }
        &.-business-section > h3 {
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }

    &.-payfac {
      width: 1440px;
      max-width: 1440px;
      display: flex;
      justify-content: center;
      .-content {
        padding: unset;
        position: relative;
        bottom: 80px;
        z-index: 3;
        margin-top: unset;
      }

      .-payfac-new {
        width: 1168px;
        max-width: 1168px;
        &.-welcome-section {
          position: relative;
          bottom: 35px;
          masthead-gradient-updated {
            h1 {
              width: 666px;
            }
            img {
              top: 2px;
            }
          }
        }
        &.-business-section {
          margin-top: spacer(66);
          .grid {
            padding: 0;
            gap: 16px;
            gridColumn {
              padding: 0;
            }
          }
          &:last-of-type {
            margin-bottom: spacer(66);
          }
        }
        &.-business-section > h3 {
          margin: 0;
          margin-bottom: 24px;
        }
      }
    }

    .-product-category-section {
      border: $borderWidth solid var(--card-product-updated-border-color);
      background-color: var(--card-product-bg-color);
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px var(--category-card-box-shadow-color),
        0px 0px 4px 0px var(--category-card-box-shadow-color-two),
        0px 6px 8px 0px var(--category-card-box-shadow-color-two);
      margin-bottom: 54px;
      padding: 32px !important;
      .-product-category-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 24px;
      }
      .-category-column {
        width: 26%;
        padding: 0;
      }
      .-products-column {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        card-category-product {
          width: 32.5%;
          height: auto;
          max-height: 182px;
        }
      }
    }
  }
  h1 {
    margin-top: 0;
    margin-bottom: spacer(5);
    color: $colorWpPrimaryHeadTextColor;
  }
  .-content {
    padding-left: spacer(132);
    padding-right: spacer(132);
  }

  .-clients-section {
    margin: 0 auto;
    padding: spacer(60) spacer(132) 0;
    .grid {
      display: grid;
      padding: 0;
      grid-template-columns: repeat(5, 20fr);
      > div {
        display: grid;
        align-items: center;
        max-height: 140px;
        &::before {
          content: '';
          display: block;
          grid-area: 1 / 1 / 2 / 2;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: none;
          max-height: inherit;
        }
      }
    }

    h4 {
      margin: 0;
      font-weight: 600;
      line-height: spacer(5);
      font-size: $font-size;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      color: $colorWpPrimaryHeadTextColor;
    }
  }

  .-welcome-section {
    masthead-gradient {
      position: relative;
      padding: 0px 132px;
    }
  }

  .-business-section {
    margin-top: spacer(4);
  }

  .-sandbox-section {
    margin-top: spacer(60);
  }

  .-features-section {
    margin-top: spacer(60);
  }

  .-testimonial-section {
    margin-top: spacer(60);
  }

  .-apis-section {
    card-product {
      .grid {
        margin: 0;
      }
    }
    h2 {
      text-transform: unset;
      line-height: 2rem;
      margin-top: 0;
      color: var(--best-apis-section-color);
    }
  }

  .-news-section {
    margin-top: spacer(60);
    grid:first-of-type {
      .grid {
        margin-bottom: spacerThree(7);
      }
    }
  }

  .-events-section {
    margin-top: spacer(60);
    margin-bottom: spacer(96);
  }

  masthead-gradient {
    .img-container {
      @extend .-gradient-image-container;
      img {
        @extend .-gradient-image;
      }
    }
  }

  .-last-section {
    margin-top: spacer(60);
    margin-bottom: spacer(60);
  }

  .-landingpage-image {
    // position: relative;
    top: spacerThree(5);
  }

  .-fintech {
    masthead-gradient {
      position: relative;
      bottom: spacer(7);
      .text-container {
        width: 49%;
      }

      .img-container {
        width: 51%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 703px;
      }
    }
    masthead-basic {
      .text-container {
        p {
          color: $colorAccentLightCoolGrey;
        }
      }
    }
  }

  .-payfac {
    masthead-gradient {
      position: relative;
      bottom: -12px;
      .text-container {
        width: 50%;
      }
      .img-container {
        width: 40%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 437px;
      }
    }
  }

  .-enterprise {
    masthead-gradient {
      position: relative;
      bottom: spacer(4);
      .text-container {
        width: 49%;
      }
      .img-container {
        width: 51%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 552px;
      }
    }
    masthead-basic {
      .text-container {
        p {
          color: $colorAccentLightCoolGrey;
        }
      }
    }
  }

  .-smb {
    masthead-gradient {
      .text-container {
        width: 45%;
        padding-right: spacer(66);
      }

      .img-container {
        width: 50%;
        @extend .-gradient-image-container;
        padding-top: 696px;
      }
    }
  }

  .-iso {
    masthead-gradient {
      .text-container {
        width: 45%;
        padding-right: spacer(66);
      }

      .img-container {
        width: 50%;
        @extend .-gradient-image-container;
        padding-top: 585px;
      }
    }
  }

  .-platforms {
    masthead-gradient {
      .text-container {
        width: 50%;
      }

      .img-container {
        width: 40%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 546px;
      }
    }

    masthead-basic {
      .text-container {
        p {
          color: $colorAccentLightCoolGrey;
        }
      }
    }
  }

  .-platforms-new {
    &.-business-section {
      h3 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin-left: 0;
      }
      p {
        margin-left: 0;
      }

      .-business-type-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        card-product-small {
          width: 18.8%;
          height: auto;
          position: relative;
        }
      }

      card-product-small {
        h3 {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  section {
    &.-landingpage.-features-section {
      margin-top: spacer(60);
    }
    &.payment-figures {
      margin-top: spacer(60);
    }
  }
  @media (min-width: $breakpoint-medium-mobile) and (max-width: $screen-md) {
    .-apis-section {
      card-product {
        padding: spacer(5);
        .grid {
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          gridcolumn {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media (max-width: $breakpoint-medium-mobile-max) {
    card-product {
      padding: spacer(5);
      .grid {
        margin: 0;
        gridColumn {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .-apis-section gridcolumn {
    padding: 0 16px;
  }
  .-news-section gridcolumn {
    padding: 0 spacer(4);
  }
}
@media (max-width: $screen-md) {
  .-apis-section gridcolumn {
    margin-bottom: spacer(5);
  }
  .-news-section grid .grid gridcolumn,
  .-events-section grid .grid gridcolumn {
    margin-bottom: spacer(5);
  }
}

@media (max-width: $screen-mobile-xs-max) {
  app-homepage {
    .-content {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
}

@media (max-width: $screen-sm-max) {
  app-homepage {
    .-content {
      padding-left: spacerThree(8);
      padding-right: spacerThree(8);
    }

    .-welcome-section {
      masthead-gradient {
        padding: 0px spacer(5);
        bottom: -30px !important;
      }
    }

    .-clients-section {
      margin-top: spacer(60);
    }

    convergence-masthead {
      .text-container {
        padding: 0 spacerThree(7);
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  .-sandbox-section {
    masthead-basic {
      padding: 0px spacer(5) !important;
    }
  }
}

@media (min-width: $screen-md-min) {
  masthead-basic {
    .text-container {
      padding-left: spacer(132);
    }
  }
}

@media (max-width: $breakpoint-big-mobile) {
  .-clients-section {
    .grid {
      grid-template-columns: repeat(2, 50fr) !important;
      div {
        margin: 0 auto;
        width: 150px;
        height: 136px;
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-lg) {
  .-payfac {
    masthead-gradient {
      .img-container {
        padding-top: 42% !important;
      }
    }
  }
}

@media (min-width: $screen-mobile-large) and (max-width: $screen-mobile-large-max) {
  .-clients-section {
    .grid {
      grid-template-columns: repeat(2, 50fr) !important;
      div {
        margin: 0 auto;
        width: 260px;
        height: 136px;
      }
    }
  }
}
@media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
  .-clients-section {
    .grid {
      grid-template-columns: repeat(3, 33fr) !important;
      div {
        margin: 0 auto;
        width: 230px;
        height: 136px;
      }
      div:last-child {
        display: none;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  app-homepage {
    .-news-section,
    .-events-section {
      h1 {
        &.-fontDisplayXSmall {
          font-size: 24px;
        }
      }
    }
    .-sandbox-section {
      h1 {
        font-size: 24px;
        &.-fontDisplayMedium {
          font-size: 24px;
        }
      }
    }

    masthead-gradient {
      .img-container {
        @extend .-gradient-image-container;
        padding-top: 82%;
      }
    }
    .-enterprise {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 70%;
        }
      }
    }
    .-fintech {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 89%;
        }
      }
    }
    .-payfac {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 82%;
        }
      }
    }
    .-platforms {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 70%;
        }
      }
    }
    .-smb {
      masthead-gradient {
        .img-container {
          top: spacerThree(10);
          @extend .-gradient-image-container;
          padding-top: 90%;
        }
      }
    }
    .-iso {
      masthead-gradient {
        .img-container {
          top: spacerThree(10);
          @extend .-gradient-image-container;
          padding-top: 75%;
        }
      }
    }
  }
}

@media (max-width: ($screen-sm - 1px)) {
  app-homepage {
    .-homepage-container {
      &.-platforms {
        width: 100%;
        padding: 0;
        .-content {
          bottom: 0px;
        }

        .-platforms-new {
          width: 100%;
          max-width: unset;
          margin-top: spacer(66);
          padding: 0px 32px 0px 32px !important;

          &:first-of-type {
            margin-top: 0;
            padding-top: 24px !important;
          }

          &:last-of-type {
            padding-bottom: 24px !important;
          }

          &:nth-of-type(2) {
            margin-top: 0;
            padding-top: spacer(66) !important;
            background-image: url('/assets/images/rectangle-bg__.png');
            background-repeat: no-repeat;
            background-size: auto;
            background-position-x: calc(50% - (100vw / 2) + 60px);
            overflow: hidden;
          }
          .-key-section {
            .grid {
              padding: 0;
              gridColumn {
                padding: 0;
                margin-bottom: 24px;
              }
            }
          }
          &.-welcome-section {
            position: relative;
            bottom: 0px;
          }
          &.-business-section {
            &:last-of-type {
              margin-bottom: spacer(42);
            }
            .-business-type-container {
              display: flex;
              flex-wrap: wrap;
              gap: 16px;
              card-product-small {
                width: 100%;
              }
            }
          }
          &:nth-of-type(3) {
            margin-top: spacer(42);
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md-sm-max) {
  app-homepage {
    .-homepage-container {
      &.-platforms {
        width: 100%;
        padding: 0;
        .-content {
          bottom: -28px;
        }

        .-platforms-new {
          width: 100%;
          max-width: unset;
          margin-top: spacer(66);
          padding: 0px 40px 0px 40px !important;

          &:first-of-type {
            margin-top: 0;
            padding-top: 24px !important;
          }

          &:last-of-type {
            padding-bottom: 24px !important;
          }

          &:nth-of-type(2) {
            margin-top: 0;
            padding-top: spacer(66) !important;
            background-image: url('/assets/images/rectangle-bg__.png');
            background-repeat: no-repeat;
            background-size: auto;
            background-position-x: calc(50% - (100vw / 2) + 24px);
            background-position-y: 35px;
            overflow: hidden;
          }
          .-key-section {
            .grid {
              padding: 0;
              flex-direction: column;
              flex-wrap: nowrap;
              gridColumn {
                padding: 0;
                margin-bottom: 24px;
                width: 100%;
              }
            }
          }
          &.-welcome-section {
            position: relative;
            bottom: -28px;
            padding-right: 0px !important;
          }
          &.-business-section {
            &:last-of-type {
              margin-bottom: spacer(42);
            }

            .-business-type-container {
              display: flex;
              flex-wrap: wrap;
              gap: 16px;
              card-product-small {
                width: 31.7%;

                h3 {
                  margin: 0;
                  margin-bottom: 8px;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 28px;
                }

                p {
                  margin-bottom: 8px;
                }

                .grid {
                  gridColumn {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: ($screen-md-sm-max + 1)) and (max-width: ($breakpoint-ipad-landscap - 1)) {
  app-homepage {
    .-homepage-container {
      &.-platforms {
        width: 100%;
        padding: 0;
        .-content {
          bottom: 0px;
        }

        .-platforms-new {
          width: 100%;
          max-width: unset;
          margin-top: spacer(66);
          padding: 0px 40px 0px 40px !important;

          &:first-of-type {
            margin-top: 0;
            padding-top: 24px !important;
          }

          &:last-of-type {
            padding-bottom: 24px !important;
          }

          &:nth-of-type(2) {
            margin-top: 0;
            padding-top: spacer(66) !important;
            background-image: url('/assets/images/rectangle-bg__.png');
            background-repeat: no-repeat;
            background-size: auto;
            background-position-x: calc(50% - (100vw / 2) + 24px);
            background-position-y: 35px;
            overflow: hidden;
          }
          .-key-section {
            .grid {
              padding: 0;
              flex-direction: column;
              flex-wrap: nowrap;
              gridColumn {
                padding: 0;
                margin-bottom: 24px;
                width: 100%;
              }
            }
          }
          &.-welcome-section {
            position: relative;
            bottom: 0px;
            padding-right: 40px !important;
          }
          &.-business-section {
            &:last-of-type {
              margin-bottom: spacer(42);
            }

            .-business-type-container {
              display: flex;
              flex-wrap: wrap;
              gap: 16px;
              card-product-small {
                width: 31.7%;

                h3 {
                  margin: 0;
                  margin-bottom: 8px;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 28px;
                }

                p {
                  margin-bottom: 8px;
                }

                .grid {
                  gridColumn {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-ipad-landscap) and (max-width: ($screen-lg - 1)) {
  app-homepage {
    .-homepage-container {
      &.-platforms {
        width: 100%;
        padding: 0;
        .-content {
          bottom: -28px;
        }

        .-platforms-new {
          width: 100%;
          max-width: unset;
          margin-top: spacer(66);
          padding: 0px 80px 0px 80px !important;

          &:first-of-type {
            margin-top: 0;
            padding-top: 24px !important;
          }

          &:last-of-type {
            padding-bottom: 24px !important;
          }

          &:nth-of-type(2) {
            margin-top: 0;
            padding-top: spacer(66) !important;
            background-image: url('/assets/images/rectangle-bg__.png');
            background-repeat: no-repeat;
            background-size: auto;
            background-position-x: calc(50% - (100vw / 2) + 24px);
            background-position-y: 35px;
            overflow: hidden;
          }
          .-key-section {
            .grid {
              padding: 0;
              flex-direction: column;
              flex-wrap: nowrap;
              gridColumn {
                padding: 0;
                margin-bottom: 24px;
                width: 100%;
              }
            }
          }
          &.-welcome-section {
            position: relative;
            bottom: -28px;
            padding-right: 80px !important;
            padding-left: 80px !important;
          }
          &.-business-section {
            &:last-of-type {
              margin-bottom: spacer(42);
            }

            .-business-type-container {
              display: flex;
              flex-wrap: wrap;
              gap: 16px;
              card-product-small {
                width: 31.7%;

                h3 {
                  margin: 0;
                  margin-bottom: 8px;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 28px;
                }

                p {
                  margin-bottom: 8px;
                }

                .grid {
                  gridColumn {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $screen-lg-desktop) {
  app-homepage {
    .-homepage-container {
      &.-platforms {
        width: 100%;
        max-width: 1440px;
        padding: 0;

        .-platforms-new {
          width: 1072px;
          max-width: 1072px;
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  app-homepage {
    .-payfac {
      masthead-gradient-updated {
        .img-container {
          display: none !important;
        }
      }
    }
  }
}
