// app-reset-password {
//     .inline-block {
//       display: inline-block;
//     }

//     .-colorPrimaryGreen{
//       color: $colorPrimaryGreen;
//     }

//     .center-text{
//       width: inherit;
//       margin: auto;
//     }

//     // .col-md-offset-1 {
//     //   // float: left;
//     //   // float: right;
//     // }

//     // .forgot-password h4 {
//     //     padding:   0em 20%;
//     // }

//     & .reset-password.-masthead {
//         // padding-bottom: 5em;
//         padding: 2em 2em 3.5em 2em;
//     }

//     .wrap {
//       // max-width: 13em;
//       line-height: 1rem;
//       vertical-align: middle;
//       width: 29%;
//     }

//     .padding-top{
//       padding-top: 2em;
//     }

//     // & .form .-question label {
//     //     margin-bottom: 5px;
//     //     margin-top:10px;
//     //   }

//     p:last-child {
//         padding-bottom: 5%;
//     }

//     .nav {
//         z-index: 100;
//         position: absolute;
//         padding: 2em;
//     }

//     .app-reset-password {
//     .inline-block {
//       display: inline-block;
//     }

//     .-colorPrimaryGreen{
//       color: $colorPrimaryGreen;
//     }

//     .center-text{
//       width: inherit;
//       margin: auto;
//     }

//     // .col-md-offset-1 {
//     //   // float: left;
//     //   // float: right;
//     // }

//     // .forgot-password h4 {
//     //     padding:   0em 20%;
//     // }

//     & .reset-password.-masthead {
//         // padding-bottom: 5em;
//         padding: 2em 2em 3.5em 2em;
//     }

//     .wrap {
//       // max-width: 13em;
//       line-height: 1rem;
//       vertical-align: middle;
//       width: 29%;
//     }

//     .padding-top{
//       padding-top: 2em;
//     }

//     // & .form .-question label {
//     //     margin-bottom: 5px;
//     //     margin-top:10px;
//     //   }

//     p:last-child {
//         padding-bottom: 5%;
//     }

//     .nav {
//         z-index: 100;
//         position: absolute;
//         padding: 2em;
//     }

//     .reset-password .line{
//         overflow: hidden;
//         padding: 0em 20%;
//     }
//     .line:before, .line:after{
//         background-color: #979797;
//         content: "";
//         display:inline-block;
//         height: 1px;
//         position: relative;
//         vertical-align: middle;
//         width: 50%;
//     }
//     .line:before{
//         right: 1em;
//         margin-left: -50%;
//     }
//     .line:after{
//         left: 1em;
//         margin-right: -50%;
//     }

//     masthead .masthead.-container {
//       min-height: 50em;
//       width: 100%;
//     }

//     .main-icon {
//       display: inline-block;
//       border-radius: 50%;
//       border: 3.5px solid #fff;
//       padding: 1.5em;
//       margin: 2em 2em;
//     }

//     .reset-password {
      
//       h2:first-of-type {
//         margin-bottom: 0;
//       }
    
//       h2:last-child {
//         margin-top: 0;
//       }
    
//       &.-cols {
//         display: flex;
//         margin: auto;
//       }
    
//       &.-row {
//         width: 100%;
//       }
      
//       &.-submit-button-wrapper {
//         display: flex;
//         justify-content: center;
//         margin-top: spacer(4);
//       }

//       & wpbutton a{
//           padding: 0 0 0 0;
//           display:block;
//       }
    
//       &.-submit-button {
//         font-size: 1.125rem;
//         font-weight: 600;
//         line-height: 1.4375rem;
//         background-color: $colorPrimaryGreen;
//         border: $borderWidth solid $colorPrimaryGreen;
//         border-radius: spacer(5);
//         color: white;
//         margin: 0 spacer(2) spacer(3) 0;
//         user-select: none;
//         display: inline-block !important;
//         transition: 0.3s;
//         width: 66%;
//         text-align: center;
    
//         padding: calc(#{spacer(3)} - #{$borderWidth})
//           calc(#{spacer(6)} - #{$borderWidth});
    
//         &:hover {
//           background-color: $colorPrimaryBlack;
//           color: $colorSupportingWhite;
//           a {
//             color: $colorSupportingWhite;
//           }
//           icon {
//             color: $colorSupportingWhite;
//           }
//         }
//       }
    
//       &.-filler {
//         margin-bottom: 24%;
//       }
    
//       &.-masthead-confirmation {
//         padding-top: 140px;
//       }
    
//       &.-confirmation {
//         height: 0;
//         overflow: hidden;
//       }
    
//       &.-failure {
//         color: $colorAccentRed;
//         text-align: center;
//       }
//       &.-question {
//         margin-left: auto;
//         margin-right: auto;
//         width: 66%;
//       }
//       ::-webkit-input-placeholder {
//         color: $colorAccentLightGray;
//       }
//       ::-moz-placeholder {
//         color: $colorAccentLightGray;
//       }
//       ::-ms-placeholder {
//         color: $colorAccentLightGray;
//       }
//       ::placeholder {
//         color: $colorAccentLightGray;
//       }
//     }
    
//     .-main-padding {
//       padding: spacer(7) 18% spacer(9) 18%;
//     }
    
//     label {
//       margin: 0;
//     }
//   }
  
//   @media (max-width: $screen-sm-max) {
//     app-login {
//       .reset-password {
//         &.-masthead {
//           padding-top: spacer(7);
//           display: inline-block;
//         }
//         &.-cols {
//           display: block;
//         }
//         & .login .form{
//           padding: 0 3em;
//         }
//       }
//       .main-icon {
//         display: table;
//         margin: auto;
//       }
//       .wrap {
//         text-align: center;
//       }
//     }
//   // }-password .line{
//   //       overflow: hidden;
//   //       padding: 0em 20%;
//   //   }
//   //   .line:before, .line:after{
//   //       background-color: #979797;
//   //       content: "";
//   //       display:inline-block;
//   //       height: 1px;
//   //       position: relative;
//   //       vertical-align: middle;
//   //       width: 50%;
//   //   }
//   //   .line:before{
//   //       right: 1em;
//   //       margin-left: -50%;
//   //   }
//   //   .line:after{
//   //       left: 1em;
//   //       margin-right: -50%;
//   //   }

//   //   masthead .masthead.-container {
//   //     min-height: 50em;
//   //     width: 100%;
//   //   }

//   //   .main-icon {
//   //     display: inline-block;
//   //     border-radius: 50%;
//   //     border: 3.5px solid #fff;
//   //     padding: 1.5em;
//   //     margin: 2em 2em;
//   //   }

//   //   .reset-password {
      
//   //     h2:first-of-type {
//   //       margin-bottom: 0;
//   //     }
    
//   //     h2:last-child {
//   //       margin-top: 0;
//   //     }
    
//   //     &.-cols {
//   //       display: flex;
//   //       margin: auto;
//   //     }
    
//   //     &.-row {
//   //       width: 100%;
//   //     }
      
//   //     &.-submit-button-wrapper {
//   //       display: flex;
//   //       justify-content: center;
//   //       margin-top: spacer(4);
//   //     }

//   //     & wpbutton a{
//   //         padding: 0 0 0 0;
//   //         display:block;
//   //     }
    
//   //     &.-submit-button {
//   //       font-size: 1.125rem;
//   //       font-weight: 600;
//   //       line-height: 1.4375rem;
//   //       background-color: $colorPrimaryGreen;
//   //       border: $borderWidth solid $colorPrimaryGreen;
//   //       border-radius: spacer(5);
//   //       color: white;
//   //       margin: 0 spacer(2) spacer(3) 0;
//   //       user-select: none;
//   //       display: inline-block !important;
//   //       transition: 0.3s;
//   //       width: 66%;
//   //       text-align: center;
    
//   //       padding: calc(#{spacer(3)} - #{$borderWidth})
//   //         calc(#{spacer(6)} - #{$borderWidth});
    
//   //       &:hover {
//   //         background-color: $colorPrimaryBlack;
//   //         color: $colorSupportingWhite;
//   //         a {
//   //           color: $colorSupportingWhite;
//   //         }
//   //         icon {
//   //           color: $colorSupportingWhite;
//   //         }
//   //       }
//   //     }
    
//   //     &.-filler {
//   //       margin-bottom: 24%;
//   //     }
    
//   //     &.-masthead-confirmation {
//   //       padding-top: 140px;
//   //     }
    
//   //     &.-confirmation {
//   //       height: 0;
//   //       overflow: hidden;
//   //     }
    
//   //     &.-failure {
//   //       color: $colorAccentRed;
//   //       text-align: center;
//   //     }
//   //     &.-question {
//   //       margin-left: auto;
//   //       margin-right: auto;
//   //       width: 66%;
//   //     }
//   //     ::-webkit-input-placeholder {
//   //       color: $colorAccentLightGray;
//   //     }
//   //     ::-moz-placeholder {
//   //       color: $colorAccentLightGray;
//   //     }
//   //     ::-ms-placeholder {
//   //       color: $colorAccentLightGray;
//   //     }
//   //     ::placeholder {
//   //       color: $colorAccentLightGray;
//   //     }
//   //   }
    
//   //   .-main-padding {
//   //     padding: spacer(7) 18% spacer(9) 18%;
//   //   }
    
//   //   label {
//   //     margin: 0;
//   //   }
//   // }
  
//   // @media (max-width: $screen-sm-max) {
//   //   app-login {
//   //     .reset-password {
//   //       &.-masthead {
//   //         padding-top: spacer(7);
//   //         display: inline-block;
//   //       }
//   //       &.-cols {
//   //         display: block;
//   //       }
//   //       & .login .form{
//   //         padding: 0 3em;
//   //       }
//   //     }
//   //     .main-icon {
//   //       display: table;
//   //       margin: auto;
//   //     }
//   //     .wrap {
//   //       text-align: center;
//   //     }
//   //   }
//   // }

  app-reset-password {      
    .-colorPrimaryGreen{
      color: $colorPrimaryGreen;
    }

    .inline-block {
      display: inline-block;
    }
  
    // .grey {
    //     color: #979797;
    // }
  
    // .col-md-offset-1 {
    //   // float: left;
    //   // float: right;
    // }
  
    // .login h4 {
    //     padding: 0em 20%;
    // }
  
    // & .valign{
    //   display: inline-block;
    //   float: none;
    //   vertical-align: middle;
    // }
  
    & .reset-password.-masthead {
        // padding-bottom: 5em;
        padding: 2em 2em 0.5em 2em;
    }
  
    .wrap {
      // max-width: 13em;
      vertical-align: middle;
      width: 29%;
    }
  
    // & .form .-question label {
    //     margin-bottom: 5px;
    //     margin-top:10px;
    //   }
  
    p:last-child {
        padding-bottom: 5%;
    }
  
    .nav {
        z-index: 100;
        position: absolute;
        padding: 2em;
    }
  
    .reset-password .line{
        overflow: hidden;
        padding: 0em 20%;
    }
    .line:before, .line:after{
        background-color: #979797;
        content: "";
        display:inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }
    .line:before{
        right: 1em;
        margin-left: -50%;
    }
    .line:after{
        left: 1em;
        margin-right: -50%;
    }
  
    masthead .masthead.-container {
      min-height: 50em;
      width: 100%;
    }
  
    .main-icon {
      display: inline-block;
      border-radius: 50%;
      border: 3.5px solid #fff;
      padding: 1.5em;
      margin: 2em 2em;
    }
  
    .reset-password {
      
      h2:first-of-type {
        margin-bottom: 0;
      }
    
      h2:last-child {
        margin-top: 0;
      }
    
      &.-cols {
        display: flex;
        margin: auto;
      }
    
      &.-row {
        width: 100%;
      }
      
      &.-submit-button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: spacer(4);
      }
  
      & wpbutton a{
          padding: 0 0 0 0;
          display:block;
      }
    
      &.-submit-button {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.4375rem;
        background-color: $colorPrimaryGreen;
        border: $borderWidth solid $colorPrimaryGreen;
        border-radius: spacer(5);
        color: white;
        margin: 0 spacer(2) spacer(3) 0;
        user-select: none;
        display: inline-block !important;
        transition: 0.3s;
        width: 66%;
        text-align: center;
    
        padding: calc(#{spacer(3)} - #{$borderWidth})
          calc(#{spacer(6)} - #{$borderWidth});
    
        &:hover {
          background-color: $colorPrimaryBlack;
          color: $colorSupportingWhite;
          a {
            color: $colorSupportingWhite;
          }
          icon {
            color: $colorSupportingWhite;
          }
        }
      }
    
      &.-filler {
        margin-bottom: 24%;
      }
    
      &.-masthead-confirmation {
        padding-top: 140px;
      }
    
      &.-confirmation {
        height: 0;
        overflow: hidden;
      }
    
      &.-failure {
        color: $colorAccentRed;
        text-align: center;
      }
      &.-question {
        margin-left: auto;
        margin-right: auto;
        width: 66%;
      }
      ::-webkit-input-placeholder {
        color: $colorAccentLightGray;
      }
      ::-moz-placeholder {
        color: $colorAccentLightGray;
      }
      ::-ms-placeholder {
        color: $colorAccentLightGray;
      }
      ::placeholder {
        color: $colorAccentLightGray;
      }
    }
    
    .-main-padding {
      padding: spacer(7) 18% spacer(9) 18%;
    }
    
    label {
      margin: 0;
    }
  }
  
  @media (max-width: $screen-sm-max) {
    app-reset-password {
      .reset-password {
        &.valign {
          display: block;
        }
        &.-masthead {
          padding-top: spacer(7);
          display: inline-block;
        }
        &.-cols {
          display: block;
        }
        & .reset-password .form{
          padding: 0 3em;
        }
      }

      .wrap {
        text-align: center;
      }
    }
  }