card-category-product {
  color: var(--card-product-text-color);
  background-color: var(--card-product-bg-color);
  padding: 16px;
  transition: 0.2s;
  a {
    &:hover {
      text-decoration: none;
    }

    icon[icon='external-link'] {
      display: none;
    }
  }

  .-subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .card-product {
    icon {
      display: inline-block;
      position: relative;
      height: 36px;
      width: 36px;
    }

    &.-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
    h3 {
      font-weight: 600;
      margin: 0 0 4px 0 !important;
      flex: 1;
      color: var(--link-color) !important;
      font-size: 16px;
      line-height: 24px;
    }
  }
  p {
    margin-bottom: 4px;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: var(--card-product-updated-text-color);
  }
  .grid {
    margin: 0;
    padding: 0;
    justify-content: space-between;

    icon {
      margin-top: spacer(1);
      margin-right: spacer(2);
    }

    gridColumn {
      margin-top: spacerThree(5);
      flex-direction: initial;
      width: 50%;
      flex-grow: unset;
      padding: 0;

      &.-align {
        width: auto;
      }

      app-link {
        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: var(--link-color);
          &:hover {
            color: var(--link-hover-color);
            text-decoration: var(--link-hover-color);
          }
        }
      }

      link-icon {
        a {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
  wpbutton {
    margin-top: spacer(42);
  }
  &:hover {
    border-radius: 12px;
    box-shadow: 4px 8px 20px 0px var(--card-product-updated-shadow-color),
      3px 4px 6px 0px var(--card-product-updated-shadow-color),
      4px 8px 20px 0px var(--card-product-updated-shadow-color);
    transform: scale(1.01);
    cursor: pointer;
  }

  @media (max-width: 575px) {
    .card-product {
      h3 {
        font-size: 1.25rem;
      }
    }
  }

  @media (min-width: $screen-md) {
    .card-product {
      display: flex;
      flex-direction: column;
      &.-title {
        display: flex;
        align-items: unset !important;
        margin-bottom: unset !important;
      }
    }

    h3 {
      margin-left: unset !important;
      margin-bottom: 12px !important;
    }

    icon {
      margin-bottom: 15px;
    }
  }
}
