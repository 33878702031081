nav-vertical-updated {
  display: block;
  position: sticky;
  bottom: 0;
  overflow-y: auto;
  min-width: 300px;
  top: 120px;
  padding: 0 spacerThree(3);
  float: left;
  align-self: start;
  ul {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;

    li {
      cursor: pointer;
      margin: unset;
      padding-left: unset;
    }

    &.-menu {
      margin-bottom: spacerThree(1);
    }
  }

  h3 {
    @extend .-fontSmall;
    color: var(--left-nav-heading-color);
    text-transform: uppercase;
    padding: 0 spacer(4) spacer(2);
    letter-spacing: 1px;
  }

  .nav-vertical {
    app-link {
      @extend .-fontMedium;
      cursor: pointer;
      a {
        color: var(--left-nav-text-color);
        &:focus {
          text-decoration: none;
        }
        &:hover,
        &.-link-active {
          color: var(--left-nav-active-text-color) !important;
          text-decoration: none;
        }
      }
    }
    &.-menu {
      &.-item {
        &.-container {
          padding: spacerThree(3) spacer(4);
          &.-no-child {
            padding: unset;
            button-icon {
              justify-content: flex-end;
            }
          }
          border-radius: spacer(2);
          button-icon {
            display: flex;
            align-items: center;
            svg > * {
              fill: var(--left-nav-text-color);
            }
          }
          app-link {
            a {
              color: var(--left-nav-text-color);
            }
          }
          &.-open {
            background-color: var(--left-nav-bg-hover-color);
          }
          &.-active {
            background-color: unset;
            &.-open {
              background-color: var(--left-nav-bg-hover-color);
              // border-radius: spacer(2);
              app-link {
                a {
                  color: var(--left-nav-active-text-color);
                }
              }
            }
            &:hover {
              background-color: var(--left-nav-bg-hover-color);
              // border-radius: spacer(2);
              app-link {
                a {
                  color: var(--left-nav-active-text-color);
                }
              }
            }
            svg > * {
              fill: var(--left-nav-active-text-color);
            }
          }
          &:hover {
            background-color: var(--left-nav-bg-hover-color);
            app-link {
              a {
                color: var(--left-nav-active-text-color)-level-1;
              }
            }
            svg > * {
              fill: var(--left-nav-active-text-color)-level-1;
            }
          }
          &.-no-child {
            app-link {
              width: 100%;
              a {
                padding: spacerThree(3) spacer(4);
                display: block;
                width: 100%;
              }
            }
            border: $hairline solid transparent;
            &.-active {
              background-color: unset;
              app-link {
                a.-link-active {
                  color: var(--left-nav-active-text-color)-level-1;
                }
              }
            }
            &:hover {
              background-color: var(--left-nav-bg-hover-color)-level-1;
              border-radius: spacer(2);
              border: $hairline solid var(--left-nav-border-color);
              a {
                color: var(--left-nav-active-text-color)-level-1;
              }
              icon svg > * {
                fill: var(--left-nav-active-text-color)-level-1;
              }
            }
          }
        }
      }
    }

    &.-menuChild {
      &.-item {
        &.-container {
          padding: spacer(2) spacerThree(8);
          button-icon {
            margin: auto 0;
            margin-right: spacerThree(3);
            icon {
              height: spacerThree(2);
              width: spacerThree(2);
              svg > * {
                fill: var(--left-nav-text-color);
              }
            }
          }
          app-link {
            a {
              word-break: break-word;
              color: var(--left-nav-text-color);
              &.-link-active {
                color: var(--left-nav-active-text-color) !important;
              }
            }
          }
          &.-hasIcon {
            app-link {
              a {
                color: var(--left-nav-text-color);
                &:focus {
                  text-decoration: none;
                }
              }
            }
          }
          &.-hasNoIcon {
            &:hover {
              app-link {
                a {
                  color: var(--left-nav-active-text-color);
                }
              }
            }
          }
        }
      }
    }

    &.-menuGrandchild {
      &.-item {
        &:hover {
          app-link {
            a {
              color: var(--left-nav-active-text-color);
            }
          }
        }
        &.-link {
          padding: spacer(2) spacer(54);
        }
      }
    }
  }
}
.nav-vertical {
  &.-expand {
    display: none;
  }
}

@media (max-width: $screen-sm-max) {
  .nav-vertical {
    &.-expand {
      display: block;
      position: fixed;
      bottom: 15px;
      left: 15px;
      z-index: 10;
    }
    &.-slide {
      overflow: hidden;
    }
    &.-slide-nav {
      width: auto !important;
      overflow: unset !important;
    }
  }
}

@media (max-width: $breakpoint-ipad-landscap) {
  nav-vertical-updated {
    .nav-vertical {
      app-link {
        a {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  nav-vertical-updated {
    .main-menu-sidebar {
      height: initial;
      overflow-x: auto;
    }
    ul {
      height: inherit;
    }
  }
}
