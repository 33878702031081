rest-verb-box {
  @extend .-thickFont;
  @extend .-fontMedium;
  @include color('background-color');

  &.-restPostColor {
    color: var(--request-method-post-text-color);
    border: 1px solid var(--request-method-post-text-color);
  }
  
  &.-restGetColor {
    color: var(--request-method-get-text-color);
    border: 1px solid var(--request-method-get-text-color);
  }
  
  &.-restDeleteColor {
    color: var(--request-method-delete-text-color);
    border: 1px solid var(--request-method-delete-text-color);
  }
  
  &.-restPatchColor {
    color: var(--request-method-patch-text-color);
    border: 1px solid var(--request-method-patch-text-color);
  }
  
  &.-restPutColor {
    color: var(--request-method-post-text-color);
    border: 1px solid var(--request-method-post-text-color);
  }

  background-color: $colorSupportingWhite;
  display: inline-block;
  padding: spacer(2) spacer(3);
  border-radius: 2px;
  user-select: none;
}
