base-card {
  .base-card {
    &.-title-container {
      display: flex;
      margin-bottom: spacer(2);
      .-vertical-line {
        border-left: 4px solid var(--base-card-left-border);
        height: spacer(5);
      }
      h3 {
        font-size: spacer(4);
        line-height: spacer(5);
        font-weight: 700;
        flex: 1;
        margin-left: spacer(3);
        color: $colorWpPrimaryHeadTextColor;
      }
    }
  }
  p {
    color: var(--base-card-text-color);
  }
  ul,
  ol {
    padding-inline-start: 20px;
    margin-top: spacer(4);
    li::marker {
      color: var(--base-card-list-marker-color);
    }
  }
}
