masthead-basic-updated {
  display: flex;
  margin: initial;
  flex-direction: row-reverse;
  justify-content: center;
  .text-container {
    width: 60%;
    align-self: center;
    h4 {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      padding-right: 0;
      color: var(--masthead-basic-updated-title-color) !important;
      margin-bottom: 16px;
    }
    p {
      color: var(--masthead-basic-updated-text-color) !important;
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      width: 472px;
    }
    wpbutton {
      margin: 0;
    }
  }

  link-icon {
    font-weight: 600;
  }

  @media (max-width: ($screen-sm - 1px)) {
    background-color: unset;
    .text-container {
      width: 100% !important;
      h4 {
        margin: 0;
        font-size: 20px !important;
        font-weight: 600;
        line-height: 30px;
      }
      p {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px !important;
        width: unset;
      }
      wpbutton {
        margin: 0;
      }
    }

    .img-container {
      display: none;
    }
  }

  @media (min-width: $screen-sm) and (max-width: ($screen-lg-desktop - 1px)) {
    width: 100% !important;
    padding: 48px 40px;
    gap: unset;
    position: relative;
    justify-content: flex-start;
    background-image: var(--masthead-basic-bg-footer-image-two);
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: -1px;
    background-size: 312px auto;
    max-width: 768px !important;
    .text-container {
      width: 58% !important;
      h4 {
        margin: 0;
        font-size: 30px !important;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.01em;
        padding-right: 0 !important;
      }
      p {
        font-size: 16px;
        line-height: 24px !important;
        padding-right: 0 !important;
        width: unset;
      }
    }

    .img-container {
      width: 43% !important;
      position: absolute;
      bottom: 0px;
      left: 0px;
      img {
        max-width: 331px;
        display: flex;
      }
    }

    &::after {
      content: '';
      width: calc((100vw - 768px) / 2);
      height: 100%;
      position: absolute;
      display: block;
      left: calc(50% + (768px / 2));
      top: 0px;
    }
    &::before {
      content: '';
      width: calc((100vw - 768px) / 2);
      height: 100%;
      background-color: var(--masthead-basic-bg-footer-image-color);
      position: absolute;
      display: block;
      right: calc(50% + (768px / 2));
      top: 0px;
    }
  }

  @media (min-width: $screen-lg-desktop) {
    .img-container {
      overflow: hidden;
      width: 53% !important;
      background-image: var(--masthead-basic-bg-footer-image);
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      justify-content: center;
    }
  }
}
