app-product-landing {
  h2 {
    color: $colorWpPrimaryHeadTextColor;
  }

  display: flex;
  max-width: 1040px;
  flex-direction: column;
  content-viewer {
    flex: 1;
    display: flex;
    flex-direction: column;
    link-icon {
      &:hover {
        color: $colorSupportingWhite;
      }
      a {
        &:hover {
          color: var(--link-icon-text-hover-color);
        }
      }
    }

    workflow-card,
    workflow-card-convergence {
      link-icon {
        a {
          color: var(--workflow-step-text-color-default);
          &:hover {
            color: var(--workflow-step-text-color-default);
          }
        }
      }
    }

    .sec-masthead-section {
      display: flex;
      padding-bottom: 32px;
      secondary-masthead {
        padding-bottom: unset;
        width: 100%;
        .-details {
          margin: unset;
          margin-top: 8px;
          max-width: 640px;
        }

        search-form {
          div {
            width: 640px;
            margin-top: 16px;
          }
          input {
            width: 100%;
          }
        }
        .-article-section {
          margin-top: 40px;
          .grid {
            margin: 0;
            padding: 0;
            margin-top: 8px;
            gap: 72px;
            gridColumn {
              gap: 8px;
              padding: 0;
            }
          }
        }
      }
      .sales-div {
        width: 27%;
        padding-top: spacer(3);
        .-light-font {
          color: var(--sales-section-light-font-color);
        }
        .-sales-text {
          padding-top: spacer(3);
          margin-bottom: 0;
        }
      }

      wpbutton {
        margin-top: spacerThree(10);
      }
    }

    .-api-text {
      max-width: 630px;
      font-size: $font-size;
      line-height: spacer(5);
      padding-bottom: spacerThree(11);
      margin: unset;
      color: var(--api-overview-api-text-color);
    }
    .-key-section {
      margin-top: spacer(7);
      display: flex;
      flex-direction: column;
      gap: 32px;
      p {
        margin: unset;
      }
      .-key-section-heading {
        font-size: spacer(5);
        line-height: spacer(30);
        font-weight: 700;
        margin: unset;
        padding-bottom: spacerThree(7);
      }

      ul,
      ol {
        padding-inline-start: 20px;
        margin-top: spacer(4);

        li::marker {
          color: var(--api-overview-bullet-icon-color);
        }
      }
      grid {
        card-product {
          p {
            font-size: 16px;
            line-height: spacer(5);
            font-weight: 400;
          }
          div.card-product.-title {
            h3 {
              line-height: 24px;
              font-size: 18px;
            }
          }
        }
      }

      &:last-of-type {
        margin-bottom: 40px;
      }
    }
    .-fontFraudVideo {
      font-size: spacer(5);
      line-height: spacer(30);
      font-weight: 700;
    }

    .-section-border {
      &:nth-child(2) {
        grid {
          gridcolumn {
            width: 32%;
          }
        }
      }
    }

    .-additional-product-features-section {
      display: flex;
      flex-direction: column;
      gap: spacer(5);
      .-heading {
        h3 {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 4px;
        }

        p {
          margin: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .-product-category-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 8px;
        .-category-column {
          padding: 0;
          h3 {
            margin-bottom: unset !important;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
          }
          .-subtitle {
            display: none;
          }
        }
        .-products-column {
          gap: 16px;
          display: flex;
          flex-wrap: wrap;
          card-category-product {
            width: 23.8%;
            height: auto;
            max-height: 182px;

            .-subtitle {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    content-viewer {
      .sec-masthead-section {
        flex-direction: column;
        .masthead-block {
          width: 100%;
        }
        .sales-div {
          width: 100%;
        }
      }

      .-key-section {
        padding-bottom: spacerThree(7);
        grid {
          .pill-count-style,
          .pill-count-style icon div {
            width: 36px;
            display: block;
          }
          div {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gridcolumn {
              width: 100%;
              flex-grow: 0;
              margin-bottom: spacerThree(11);
            }
          }
        }
      }

      .-section-border {
        &:nth-child(2) {
          grid {
            gridcolumn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm) and (max-width: $breakpoint-sm-desktop-max) {
    content-viewer {
      .-key-section {
        padding-bottom: spacerThree(7);
        grid {
          .pill-count-style,
          .pill-count-style icon div {
            width: 36px;
            display: block;
          }
          div {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gridcolumn {
              width: 49%;
              flex-grow: 0;
              margin-bottom: spacerThree(7);
              padding: 0 spacer(3);
            }
          }
        }
      }
    }
  }

  @media (min-width: $breakpoint-desktop) {
    content-viewer {
      .-key-section {
        // padding-bottom: spacerThree(7);
        grid {
          .pill-count-style,
          .pill-count-style icon div {
            width: 36px;
            display: block;
          }
          .grid {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding: 0;
            gap: 24px;
            // margin-bottom: 32px;
            gridcolumn {
              width: 32%;
              flex: 1;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
