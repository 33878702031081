$stroke-brightness-percent: 25%;
$http: (
  get: $colorAccentLightPurple,
  patch: $colorAccentDeepBlue,
  put: $colorAccentLightBlue,
  post: $colorAccentTeal,
  delete: $colorAccentRed,
  update: $colorAccentLightRed,
  then: $colorAccentLightBlue,
  returns: $colorAccentDarkTeal,
);

diagram-convergence {
  .diagram {
    &.-parent {
      display: flex;
      margin-top: 2rem;
      background: var(--diagram-default-bg-color);
      overflow: hidden;
      min-height: 37.5rem;
      // max-width: $dimension-sidebar-body-max-width;
      max-width: 1200px;
      border: $hairline solid var(--diagram-default-bg-border-color);
      border-radius: 0.625rem;
    }

    &.-sidebar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: 15rem;
      min-width: 15rem;
      padding: 3rem;
      padding-right: 0.5rem;
    }

    &.-scenarios {
      &.-wrapper {
        width: 300px;
        margin-bottom: spacer(4);
      }
      label {
        display: flex;
      }
    }
    &.form {
      &.-group-element {
        input {
          &:checked ~ .-group-element-radio {
            border: 2px solid var(--diagram-radio-color);
          }
          &:checked ~ .-group-element-label {
            color: var(--diagram-default-title-color);
          }
        }
        &:hover input ~ .-group-element-radio {
          border: 2px solid var(--diagram-radio-color);
        }
        &:hover input ~ .-group-element-label {
          color: var(--diagram-radio-color);
        }
        .-group-element-radio {
          height: 20px;
          width: 20px;
          top: 2px;
          &:after {
            height: 10px;
            width: 10px;
            top: 3px;
            left: 3px;
            background: var(--diagram-radio-color);
          }
        }
      }
      &.-group-element-label {
        font-size: 1rem;
        line-height: spacer(5);
        font-weight: 400;
      }
    }
    &.-information {
      &.-title {
        font-size: $font-size;
        line-height: spacer(5);
        text-transform: uppercase;
        display: block;
        margin-top: 0;
        margin-bottom: 0;

        &:first-child {
          margin-top: 0;
          font-weight: 600;
          margin-bottom: spacer(3);
          color: var(--diagram-default-title-color);
        }
      }
      &.-description {
        line-height: spacer(5);
        font-size: $font-size;
        margin: 0;
        font-weight: 400;
        color: var(--diagram-default-text-color);
      }
    }

    &.-diagram-convergence {
      width: 100%;
      svg {
        display: block;
        pointer-events: none;
        height: auto;
        top: 0;
        padding-top: 2rem;
        & .node,
        & .connector {
          @each $method, $color in $http {
            &.#{$method} {
              &.connector {
                rect {
                  fill: $color;
                }
              }
              &.active {
                circle {
                  fill: $color;
                  stroke: $colorSupportingWhite;
                }
                path {
                  stroke: $color;
                }
              }
            }
          }
        }
        & .node {
          &.type-api,
          &.type-user {
            &.active {
              circle {
                stroke-width: 4px;
              }
            }
            text.label {
              font-weight: bold;
              opacity: 1;
            }
            text.subtitle {
              font-size: $font-size;
              font-weight: bold;
              fill: $colorSupportingWhite;
            }
            circle {
              fill: var(--diagram-start-bg-color);
              stroke: $colorTintPrimaryBlackT60;
              stroke-width: 0px;
            }
          }
          &.type-user {
            text.label {
              @extend .-fontMedium;
              // font-size: $fontMedium;
              fill: var(--diagram-default-text-color);
              text-transform: uppercase;
              opacity: 1;
            }
            &.active circle,
            circle {
              display: none;
            }
          }
          &.active {
            text.label {
              @extend .-fontMedium;
              // font-size: $fontSmall;
              fill: var(--diagram-default-text-color);
              opacity: 1;
            }
            circle {
              fill: $colorAccentRed;
              stroke: mix($colorAccentRed, $colorTintPrimaryBlackT60, $stroke-brightness-percent);
              stroke-width: 3px;
            }
          }
          text.label {
            @extend .-fontSmall;
            fill: var(--diagram-default-text-color);
            // font-size: $fontSmall;
            font-weight: 600;
            opacity: 0.8;
          }
          rect {
            fill: var(--diagram-default-bg-color);
          }
          circle {
            fill: $colorCircleSvgGray;
            stroke: mix($colorAccentRed, $colorTintPrimaryBlackT60, $stroke-brightness-percent);
            stroke-width: 0px;
          }
        }
        & .connector {
          &.active {
            path {
              stroke: $colorAccentRed;
              stroke-dasharray: 6, 6;
              stroke-width: 2.5px;
            }
            text,
            rect {
              opacity: 1;
            }
          }
          path {
            fill: none;
            stroke-dasharray: 6, 6;
            animation: dash 50s infinite linear;
            stroke: var(--diagram-default-text-color);
            stroke-width: 1.5px;
          }
          text,
          rect {
            opacity: 0;
          }
          text {
            // @extend .-fontSmall;
            fill: $colorPrimaryBlack;
            // font-size: $font-size-sm;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: spacer(4);
            text-transform: uppercase;
          }
        }
      }
    }
    &.-fallback {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .t {
      transition: opacity 0.3s ease-in-out, fill 0.3s ease-in-out, r 0.3s ease-in-out;
      will-change: opacity, fill, r;
    }
  }
}

@media (max-width: $screen-md-min) {
  diagram-convergence {
    .diagram {
      flex-direction: column;
      &.-sidebar {
        width: 100%;
        text-align: unset !important;
        border-right: none;
        border-bottom: unset !important;
        padding: 3rem 0.5rem 3rem 3rem;
      }

      &.-information {
        &.-title,
        &.-description {
          margin-top: 0;
          padding: 15px;
          margin-bottom: 0px;
        }
        &.-description {
          // font-size: $font-size-sm;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
          padding: 0 15px;
        }
      }

      &.-scenarios {
        &.wpdh-form-element {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin: 0;
          margin-bottom: 15px;

          .wpdh-form-element__option-wrapper {
            width: 33.3%;

            &:first-child {
              margin-top: 5px;
            }

            label {
              padding: 15px 0;
              width: 94%;
              height: 100%;
              border: 1px solid $colorTintPrimaryBlackT60;
            }
          }
        }
      }
      &.-diagram-convergence {
        svg {
          & .connector {
            path {
              animation: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xs-min) {
  diagram-convergence {
    .diagram {
      &.-diagram-convergence {
        svg {
          .node {
            .type-user {
              text.label {
                // @extend .-fontLarge;
                // font-size: $font-size-lg;
                font-size: 1.125rem;
                font-weight: 600;
                line-height: 1.4375rem;
              }
            }
            &.active {
              text.label {
                // @extend .-fontLarge;
                // font-size: $font-size-lg;
                font-size: 1.125rem;
                font-weight: 600;
                line-height: 1.4375rem;
              }
            }
            text.label {
              // @extend .-fontMedium;
              // font-size: $font-size-md;
              font-size: 1rem;
              font-weight: 600;
              line-height: 1.25rem;
            }
          }
          .connector {
            text {
              // @extend .-fontSmall;
              // font-size: $font-size-sm;
              font-size: 0.875rem;
              font-weight: 600;
              line-height: 1.25rem;
              font-weight: bold;
            }
          }
        }
      }
      &.-scenarios {
        &.wpdh-form-element {
          .wpdh-form-element__option-wrapper {
            width: 50%;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-mobile-large) and (max-width: $screen-md) {
  diagram-convergence {
    .diagram {
      &.-sidebar {
        flex-direction: row !important;
      }
      &.-scenarios {
        flex: 1;
      }
      &.-information {
        flex: 1;
      }
    }
  }
}
@media (max-width: $breakpoint-desktop) {
  diagram-convergence {
    .diagram {
      &.form {
        &.-group-element-label {
          font-size: 14px;
        }
      }
    }
  }
}
