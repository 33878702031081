secondary-masthead {
  padding-bottom: spacerThree(10);
  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 700;
    margin: 0px;
    padding-top: 35px;
    color: var(--secondary-masthead-title-color);
  }
  div.-fontDisplayXSmallHeading {
    margin-bottom: spacerThree(3);
    color: var(--secondary-masthead-title-color);
  }
  .-sub-heading {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-bottom: unset;
    color: var(--secondary-masthead-title-color);
  }
  .-details {
    font-size: $font-size;
    line-height: 1.5rem;
    font-weight: 400;
    width: 90%;
    margin-bottom: spacerThree(7);
    margin-top: spacer(3);
  }
  .-article-section {
    b {
      font-size: 20px;
      line-height: 28px;
    }
    a {
      font-weight: 400;
      line-height: 22px;
    }
    .grid {
      gridColumn {
        gap: spacer(3);
        app-link {
          a {
            &:hover {
              color: var(--card-product-link-hover-color);
              text-decoration: var(--card-product-link-hover-decoration);
            }
          }
        }
      }
    }
  }
  .-display-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .-container-details {
    width: 90%;
    margin-top: spacerThree(3);
  }
  .-links {
    display: flex;
    link-icon {
      line-height: spacer(4);
      font-weight: 600;
      font-size: 0.875rem;
      margin-top: spacer(4);
    }
    link-icon:nth-child(2) {
      padding-left: spacer(4);
    }
  }
  .breadcrumbs {
    line-height: 1rem;
    font-size: 0.875rem;
    &.-list {
      li {
        a:hover {
          color: $colorSupportingWhite !important;
        }
      }
    }
  }
  .-availability-section {
    display: flex;
    align-items: baseline;
    .-text {
      padding: 0 10px 0 5px;
      flex: 1;
      margin-bottom: 0;
    }
    .-pipe-separator {
      padding: 0 3px;
    }
  }
  wpbutton {
    margin: 21px 6px 12px 0;
  }
}
@media (max-width: $screen-laptop-sm) {
  secondary-masthead {
    .-details {
      width: 100%;
    }
    .-display-link {
      display: block;
    }
  }
}
@media (max-width: $screen-xs-max) {
  secondary-masthead {
    div.-fontDisplayXSmallHeading {
      font-size: 26px;
      font-weight: 700;
      line-height: 2rem;
    }
  }
}
