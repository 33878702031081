restful-checkbox {
  .restful-checkbox {
    &.-checkbox-text {
      margin-left: spacer(6);
      font-size: 1rem;
    }

    &.-bottom-subtext {
      display: block;
      padding-left: spacer(3);
      margin-left: spacer(5) !important;
    }

    &.-checkbox-verb {
      margin-left: spacer(3);
    }

    &.-checkbox-subtext {
      margin-left: spacer(3);
      font-size: 1rem;
      font-weight: 400;
      color: var(--workflow-selection-checkbox-subtext-color);
    }
  }
}
