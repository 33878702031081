$linkIconleftMargin: spacer(2);

link-icon {
  display: flex;
  &:hover {
    a > icon {
      margin-left: calc(#{$linkIconleftMargin} * 2);
    }
  }
  &.hideButton {
    display: none !important;
  }
  .link-icon {
    &.-parent {
      position: relative;

      &.-text {
        margin: auto 0;
      }
    }
  }
  .-link-blue-hover {
    &:hover {
      color: $colorAccentLightBlue !important;
    }
  }
  .-link-green-text {
    color: $colorPrimaryGreen;
  }

  .-link-blue-text {
    color: var(--link-icon-color);
  }

  a {
    cursor: pointer;
    display: flex;
    // font-size: inherit;
    color: var(--link-icon-text-color);

    icon {
      transition: margin-left 250ms;
      text-align: initial;
      margin: auto 0;
      margin-left: $linkIconleftMargin;
      svg > * {
        fill: var(--link-icon-color);
      }
    }

    &:focus,
    &:hover {
      text-decoration: none;
      color: var(--link-icon-text-hover-color);
    }
  }
}
