@mixin card-style {
  padding-top: spacer(4);
  padding-bottom: spacer(4);
  flex-grow: 1;
  width: 32%;
}
@mixin card-sm-style {
  padding-top: spacer(4);
  padding-bottom: spacer(4);
  flex-grow: 1;
  width: 100%;
}
@mixin card-common-style {
  .-card {
    padding-left: spacer(3);
    padding-right: spacer(3);
    padding-bottom: 14px;
    padding-top: 14px;
    margin: 0 spacer(3);
    width: 100%;
    &:first-of-type {
      padding-top: 32px;
      padding-left: spacer(3);
    }
  }
}
.-bottom-offset {
  position: absolute;
  top: -102px;
}

.-no-bottom-offset {
  position: absolute;
  top: -14px;
}
app-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    line-height: 36px;
    margin: 0 auto;
    max-width: 311px;
    color: $colorWpPrimaryHeadTextColor;
  }
  h4 {
    font-weight: 600;
    margin-bottom: spacer(2);
    margin-top: 0;
    color: $colorWpPrimaryHeadTextColor;
  }
  p {
    font-weight: 600;
  }

  .-contact-container {
    position: relative;
    // display: flex;
    // flex-direction: column;
    width: 100%;
    p {
      font-weight: 400;
      line-height: 28px;
    }
  }
  .footer {
    h1 {
      color: var(--footer-looking-for-color);
    }
    &.-primary {
      max-width: 1536px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      padding: spacer(54) 0 spacer(7);
      background-color: var(--body-background-color);
      width: 100%;
      .-card {
        @include card-style;

        &:first-of-type {
          padding-left: spacer(66);
          span {
            display: block;
          }
        }

        &:last-child {
          padding-left: spacer(36);
        }
        wpbutton {
          margin-top: spacer(2);
        }
      }
      .-contact {
        border-bottom: 1px solid var(--footer-big-separator-color);
        border-left: 0;
        border-right: 0;
        border-top: 1px solid var(--footer-big-separator-color);
      }
      .-channelContainer {
        margin-top: spacer(4);
      }
      .-link-icon-wrapper {
        a {
          color: var(--link-color);
        }
        &:hover {
          color: var(--link-hover-color);
          text-decoration: none;
        }
        &:hover icon svg > * {
          fill: var(--footer-social-icon-hover-color);
        }
        icon {
          margin-left: spacer(2);
        }
        icon svg > * {
          fill: var(--footer-social-icon-color);
        }
        a:hover > icon svg > * {
          fill: var(--footer-social-icon-hover-color);
        }
      }
      &.-started-btn {
        margin-bottom: spacer(7);
      }
    }
    &.-secondary {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: spacer(5) 0;
      background-color: var(--footer-secondary-bg-color);
      .-secondary-container {
        display: flex;
        flex-wrap: wrap;
        flex: 0 1 1296px;
        .-card {
          @include card-style;
          display: flex;
          justify-content: center;
          align-items: center;
          &:last-child {
            padding-left: spacer(36);
          }
        }
        .-contact {
          wpbutton.-secondary {
            margin: 0;
          }
        }
        &.-started-btn {
          margin-bottom: spacer(7);
        }
      }
    }

    &.-contact-primary {
      width: 100%;
      // background-color: var(--body-background-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--branded-footer-bg-color);
      masthead-basic-updated {
        width: 1440px;
        max-width: 1440px;
      }
    }
    .-support h3,
    .-contact h3 {
      margin-top: 0;
      margin-bottom: spacer(3);
      color: $colorWpPrimaryHeadTextColor;
    }
    .-stay-connected {
      margin-top: spacer(7);
    }
    .-link-icon-wrapper {
      a {
        color: var(--link-color);
      }
      a:hover {
        color: var(--link-hover-color);
        text-decoration: none;
      }
      &:hover icon svg > * {
        fill: var(--footer-social-icon-hover-color);
      }
      icon {
        margin-left: spacer(2);
      }
      icon svg > * {
        fill: var(--footer-social-icon-color);
      }
      a:hover > icon svg > * {
        fill: var(--footer-social-icon-hover-color);
      }
    }
    .-channel {
      h4 {
        margin-bottom: spacer(2);
      }
      p {
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 0;
      }
      p:hover {
        color: $colorSupportingWhite;
      }
      icon svg > * {
        fill: var(--footer-social-icon-color);
      }
      app-link {
        font-size: 18px;
        font-weight: 600;
      }
      a:hover > icon svg > * {
        fill: var(--footer-social-icon-hover-color);
      }
    }
    .-social {
      padding: 0;
      padding-inline-start: 0;
      display: flex;
      flex-direction: row;
      margin-top: spacer(2);

      .-border {
        border: 2px solid var(--footer-social-icon-color);
        border-radius: 50%;
        height: 24px;
        width: 24px;

        &:hover {
          color: var(--footer-social-icon-hover-color);
          border-color: var(--footer-social-icon-hover-color);
        }
      }

      a {
        height: spacer(5);
        width: spacer(5);
        border-radius: 50%;
        margin-right: spacer(3);

        icon svg > * {
          fill: var(--footer-social-icon-color);
        }

        &:hover {
          icon svg > * {
            fill: var(--footer-social-icon-hover-color);
          }
        }
      }
    }

    &.-container {
      background: var(--body-background-color);
      border-top: $hairline solid var(--small-footer-border-top);
      padding: spacer(5) 0;
      width: 100%;
      display: flex;
      justify-content: center;
      .-copyright {
        display: flex;
        justify-content: center;
        flex: 0 1 1296px;
        flex-wrap: wrap;
        p {
          margin: 0;
        }
        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          text-align: center;
          margin: 0;
          padding-left: spacer(5);
          li {
            margin: 0;
            padding: 0 spacer(3);
            a {
              display: inline-block;
              color: var(--link-color);
              width: 100%;
              font-weight: 600;
              icon {
                display: none;
              }
            }
            a:hover {
              color: var(--link-hover-color);
              text-decoration: none;
            }
            icon svg > * {
              fill: var(--link-color);
            }
            a:hover icon svg > * {
              fill: var(--link-hover-color);
            }
          }
        }
      }
    }
  }
}
social-network-share {
  position: fixed;
  z-index: $layer-back-to-top;
  right: 18px !important;
  bottom: 125px !important;
  min-width: 0;
  z-index: 99;
}
@media (max-width: $screen-md) {
  app-footer {
    .footer {
      padding: spacer(5);
      &.-primary {
        padding: spacer(4) 0;
        .-card {
          padding-top: spacer(4);
        }
        .-channelContainer {
          flex-flow: column;
        }
        .-card {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      &.-secondary {
        padding-bottom: 0;
        .-card {
          &:last-child {
            padding-top: spacer(5);
          }
        }
      }
      .-stay-connected {
        margin-top: 0;
        padding-bottom: 0;
      }
      .-card {
        width: 100%;
        text-align: center;
        &:first-of-type {
          padding: 0 spacer(5) spacer(4);
          h1 {
            margin: 0;
            font-size: 24px;
            max-width: 100%;
          }
        }
      }
      &.-copyright {
        text-align: center;
        height: auto;
        margin-top: spacer(1);
        display: block;
      }

      .-channel {
        p:last-child {
          margin-bottom: 10px;
        }
      }
      .-channelContainer,
      .-social {
        justify-content: center;
      }

      &.-container {
        padding-bottom: spacer(3);
        padding-left: spacer(4);
        padding-right: spacer(4);
        .-copyright {
          p {
            margin-bottom: spacer(5);
          }
          ul {
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
            padding: 0 spacer(3);
            li {
              padding: 0 spacer(3) spacer(3);
            }
          }
        }
      }
    }
  }
}
@media (min-width: $screen-md) {
  app-footer {
    .footer {
      &.-primary {
        .-card {
          padding: 0 spacer(6) spacer(3);
        }
        .-contact {
          border-bottom: 0;
          border-left: 1px solid var(--footer-big-separator-color);
          border-right: 1px solid var(--footer-big-separator-color);
          border-top: 0;
        }
        .-channel {
          width: 50%;
        }
      }
      &.-secondary {
        .-card {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile-small-max) {
  app-footer {
    flex-direction: column;
    .footer {
      &.-primary {
        @include card-common-style; // have to deifne this as other will override
      }
      &.-secondary {
        @include card-common-style; // have to deifne this as other will override
        .-secondary-container {
          flex-wrap: nowrap;
          flex-direction: column;
          align-items: center;
          .-card {
            padding-top: 13px;
            padding-bottom: 13px;
            width: 100%;
            &:first-of-type {
              padding-top: spacer(6);
              padding-left: spacer(3);
            }
          }
          .-support {
            padding-top: spacer(5);
            padding-bottom: spacer(5);
          }
        }
      }
      &.-container {
        .-copyright {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-mobile-small-max) {
  app-footer {
    flex-direction: column;
    .footer {
      &.-primary {
        @include card-common-style; // have to deifne this as other will override
      }
    }
  }
}

@media (min-width: $screen-mobile-small-max) and (max-width: $screen-mobile-large-max) {
  app-footer {
    .footer {
      &.-secondary {
        .-contact-bottom {
          border-bottom: 1px solid var(--footer-big-separator-color);
          border-left: 0;
          border-right: 0;
          width: 100%;
        }
        @include card-sm-style; // have to deifne this as other will override
        .-secondary-container {
          .-card {
            width: 50%;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-mobile-small-max) and (max-width: $screen-mobile-large-max) {
  app-footer {
    .footer {
      &.-secondary {
        .-contact-bottom-card1 {
          border-bottom: 1px solid var(--footer-big-separator-color);
          border-left: 0;
          border-right: 0;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile-large-max) {
  app-footer {
    .footer {
      &.-secondary {
        .-contact-bottom {
          border-bottom: 1px solid var(--footer-big-separator-color);
          border-left: 0;
          border-right: 0;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: ($screen-sm - 1px)) {
  app-footer {
    .-contact-container > .footer {
      padding: 0;
      background-image: var(--footer-bg-image);
      background-repeat: no-repeat;
      background-position-y: 0px;
      background-position-x: 0px;
      background-size: 280px auto;
      &.-contact-primary {
        masthead-basic-updated {
          width: 100%;
          max-width: unset;
          padding: 40px 32px 40px 32px;
        }
      }
      &.-container {
        padding: 32px;
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: ($screen-lg-desktop - 1px)) {
  app-footer {
    .footer {
      &.-contact-primary {
        padding: 0;
      }

      &.-container {
        padding: 32px spacer(4);
      }
    }
  }
}

@media (min-width: $screen-lg-desktop) {
  app-footer {
    .footer {
      &.-contact-primary {
        masthead-basic-updated {
          width: 100%;
          max-width: 1440px;
          justify-content: space-between;
        }
      }
    }
  }
}

wpbutton {
  margin: 6px spacer(2) spacer(3) 0;
}
