codeBlock {
  .extra-small-size {
    height: 120px;
  }
  .small-size {
    height: 180px;
  }
  .medium-size {
    height: 400px;
  }
  .large-size {
    height: 650px;
  }
  .code-block {
    &.-parent {
      @extend .-fontSmall;
      margin: 1rem 0;
      overflow: hidden;
      border-radius: 0.5rem;
    }

    &.-nav {
      background: var(--code-block-head-bg-color);
      display: flex;
      @include list-reset;

      li {
        button {
          @include button-reset;
          font-size: $font-size;
          padding: 1.2rem 1rem;

          &.is-active {
            color: $colorPrimaryGreen;
          }
        }

        &::before {
          opacity: 0;
        }
      }
    }

    &.-results {
      background-color: var(--code-block-bg-color);
      border: $hairline solid var(--code-block-bg-container-border-color);
      position: relative;
      padding: 1rem;
      overflow: hidden;
    }

    &.-contents {
      max-height: 0;
      overflow-y: hidden;

      &.is-active {
        max-height: none;
      }
    }

    &.-result {
      overflow-x: auto;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:focus {
        outline: 0;
      }

      &.line-numbers {
        position: relative;
        padding-left: 4rem;
        counter-reset: linenumber;

        & > code {
          font-size: inherit;
          border: unset;
          position: relative;
          background-color: transparent;
        }

        .line-numbers-rows {
          position: absolute;
          pointer-events: none;
          top: 0;
          font-size: 100%;
          left: -4rem;
          width: 3rem;
          letter-spacing: -1px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          & > span {
            pointer-events: none;
            display: block;
            counter-increment: linenumber;

            &:before {
              content: counter(linenumber);
              color: $colorTintPrimaryBlackT60;
              display: block;
              padding-right: 1rem;
              text-align: right;
            }
          }
        }
      }
    }
    &.-copy-button {
      @include button-reset;
      color: var(--code-block-text-color);
      font-weight: bold;
      padding: 0;
      width: spacer(66);
      border: 0;
      background-color: transparent;
      overflow: hidden;
      position: absolute;
      font-size: $font-size-xs;
      top: -2px;
      right: 32px;
      background-image: url('#{$path-icons}/copy.svg');
      background-position: 3.2rem;
      background-size: 0.8rem;
      background-repeat: no-repeat;
      z-index: 1;

      &:active {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &.-success {
      @extend .-fontXSmall;
      position: absolute;
      top: 0.1rem;
      // font-size: $font-size-xs;
      // line-height: $font-size-xs;
      right: 4rem;
      height: 2rem;
      display: flex;
      align-items: center;
      transform: translateY(-100%);

      &::after {
        content: '';
        // background-image: url("#{$path-icons}/thumbs-up-green.svg");
        background-position: center top 13px;
        background-size: 0.8rem;
        background-repeat: no-repeat;
        height: 2.5rem;
        width: 2rem;
      }

      &.is-animating {
        animation: animate-in 1.5s ease;
      }
    }
    body::-webkit-scrollbar {
      width: 0.5rem;
    }
    body::-webkit-scrollbar-track {
      background: transparent;
    }
    body::-webkit-scrollbar-thumb {
      background-color: $colorPrimaryBlack;
      border-radius: 1rem;
      border: 3px solid transparent;
    }
    pre {
      background-color: var(--code-block-bg-color);
      border-color: transparent;
      font-size: 13px;
      line-height: 1.428571429;
      margin-bottom: 0;
    }
  }
}

@keyframes animate-in {
  25% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 0;
  }
}

ul.code-block .-nav {
  line-height: none;
  padding-left: 1rem;

  li {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: $screen-lg-min) {
  .wpdh-right-content {
    .code-block {
      &.-results {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    ul.code-block .-nav {
      &.is-fixed {
        position: fixed;
        top: $dimension-nav-height;
        width: auto;
        right: 0;
        left: calc((100% - #{$dimension-sidebar-width}) / 2 + #{$dimension-sidebar-width});
        z-index: $layer-codeblock-nav;
      }
    }
  }
}
